import React from "react";
import "../services/services.scss";
import Demisphere from "../../../images/service-demisphere.svg";
import Cylinder from "../../../images/service-cylinder.svg";
import Disk from "../../../images/service-disk.svg";
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl";

class Services extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      callbackMount: this.props.callbackMount
    }
  }

  componentDidMount() {
    // Callback for parent
    this.state.callbackMount('services');
  }

  render() {
    return (
      <div className="section services" data-header-color="white">
        <div className="services__wrapper">
          <div className="services__section">
            <FormattedMessage id="services__title.section" />
          </div>
          <h2 className="services__title">
            <FormattedMessage id="services__title.title" />
          </h2>
          <div className="services__list">
            <div className="service">
              <img
                className="service__img"
                src={Demisphere}
                alt="Discovery Workshop"
              />
              <div className="service__text__wrapper">
                <h3 className="service__title">
                  <FormattedMessage id="services__discovery.title" />
                </h3>
                <div className="service__label">
                  <FormattedMessage id="services__discovery.estimate" />
                </div>
              </div>
              <p className="service__desc">
                <FormattedMessage id="services__discovery.desc" />
              </p>
            </div>
            <div className="service">
              <img
                className="service__img"
                src={Cylinder}
                alt="Discovery Workshop"
              />
              <div className="service__text__wrapper">
                {" "}
                <h3 className="service__title">
                  <FormattedMessage id="services__poc.title" />
                </h3>
                <div className="service__label">
                  <FormattedMessage id="services__poc.estimate" />
                </div>
              </div>
              <p className="service__desc">
                <FormattedMessage id="services__poc.desc" />
              </p>
            </div>

            <div className="service">
              <img className="service__img" src={Disk} alt="Discovery Workshop" />
              <div className="service__text__wrapper">
                <h3 className="service__title">
                  <FormattedMessage id="services__tailor.title" />
                </h3>
                <div className="service__label">
                  <FormattedMessage id="services__tailor.estimate" />
                </div>
              </div>
              <p className="service__desc">
                <FormattedMessage id="services__tailor.desc" />
              </p>
            </div>

            {/* <div className="service">
            <img className="servce__img" src={Square} alt="Discovery Workshop" />
            <h3 className="service__title">Scaling tools for everyone</h3>
            <div className="service__label">strat + design + tech</div>
            <p className="service__desc">
              But if we believe in your project and think what we are building can
              benefit other brands we decide together of the way we collaborate
              and we might decide to invest teams and
            </p>
          </div> */}
          </div>
        </div>
      </div>
    );
  } 
}

export default injectIntl(Services);

import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Hero from "./home/hero/hero";
import Process from "./home/process/process";
import Products from "./home/products/products";
import Clients from "./home/clients/clients";
import Services from "./home/services/services";
import Contact from "./home/contact/contact";

// Init component.
class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mount: []
    }

    // Bind
    this.callbackMount = this.callbackMount.bind(this);
  }

  async callbackMount(data) {
    // Add component mount to the state
    this.setState((prevState) => {
      return {mount: [...prevState.mount, data]};
    })
  }

  render() {
    // Return App.
    return (
      <Layout mount={this.state.mount}>
        <SEO title="Saastory, turning great ideas into amazing digital products." description="Saastory is a studio that builds tools to enhance brand communication and digital transformation." url="https://saastory.com" image="https://saastory.com/static/keep/saastory-og-cover.png" />
        <Hero callbackMount={this.callbackMount}></Hero>
        <Products callbackMount={this.callbackMount}></Products>
        <Services callbackMount={this.callbackMount}></Services>
        <Process callbackMount={this.callbackMount}></Process>
        <Clients callbackMount={this.callbackMount}></Clients>
        <Contact callbackMount={this.callbackMount}></Contact>
      </Layout>     
    )
  }
}

export default IndexPage;

import React from "react";
import "../contact/contact.scss";
import contactIllu from "../../../images/contact-illu.svg";
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl";

class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      callbackMount: this.props.callbackMount
    }
  }

  componentDidMount() {
    // Callback for parent
    this.state.callbackMount('contact');
  }

  render() {
    return (
      <div className="section contact" data-header-color="white">
        <div className="contact__wrapper">
          <div className="contact__wrapper__left">
            <h2 className="contact__title">
              <FormattedMessage id="contact.title" />
            </h2>
            <div className="contact__desc">
              <FormattedMessage id="contact.desc" />
            </div>
          </div>
          <div className="contact__wrapper__right">
            <a
              href="mailto:contact@saastory.com"
              target="_blank"
              rel="noopener noreferrer"
              className="contact__button"
            >
              contact@saastory.com
            </a>
            <img
              src={contactIllu}
              className="contact__illu"
              alt="Saastory Contact"
            />
          </div>
        </div>
      </div>
    );
  } 
}

export default injectIntl(Contact);

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
// const Layout = ({ children }) => {

import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Header from "./header";
import "./layout.scss";
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl";

class Layout extends React.Component {
  render() {
    return (
      <>
        <Header mount={this.props.mount} />

        <main id="main">{this.props.children}</main>
        <footer>
          <span className="blue">
            <FormattedMessage id="footer.text" />
          </span>
          <a
            href="https://www.reputationsquad.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Reputation Squad
          </a>
          {" © "}
          <span className="blue">{new Date().getFullYear()}</span>
          <Link className="legals" to="/page-legals/">
            <FormattedMessage id="footer.legal" />
          </Link>
        </footer>
      </>
    );
  } 
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default injectIntl(Layout);
import React from "react";
import "./products.scss";
import Product from "./product-item";
import Experiments from "./experiments";
import {injectIntl, FormattedMessage } from "gatsby-plugin-intl";

class Products extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      messages: props.intl.messages,
      callbackMount: this.props.callbackMount
    }
  }

  imagesPath = require.context(
    "../../../../src/images",
    false,
    /\.(png|jpe?g|svg)$/
  );

  componentDidMount() {
    // Loop over messages
    let products = [];
    Object.keys(this.state.messages).map(key => {
      // Get only production items
      if (!key.match(/^products__item./)) return null;

      // Split the key
      const key_split = key.split(".");

      // Recreate the products array
      if (!products[key_split[1]]) products[key_split[1]] = {};
      products[key_split[1]][key_split[2]] = this.state.messages[key];

      return null;
    });

    this.setState({products: products});

    // Callback for parent
    this.state.callbackMount('products');
  }

  render() {
    return (
      <div className="section products" id="products" data-header-color="dark">
        <div className="products__wrapper">
          <div className="section__products">
            <FormattedMessage id="products__title.section" />
          </div>
          <h2 className="products__title">
            <FormattedMessage id="products__title.title" />
          </h2>

          <div className="pattern__products"></div>
          <div className="product-list__wrapper">
            {this.state.products.map((product, index) => {
              return (
                <Product
                  key={index}
                  productImg={this.imagesPath(product.img)}
                  productLogo={this.imagesPath(product.logo)}
                  productTitle={product.title}
                  productDesc={product.desc}
                  productModel={product.model}
                  productAudience={product.audience}
                  productDate={product.since}
                  productButton={product.button}
                  productButtonUrl={product.url}
                />
              );
            })}
          </div>

          <Experiments callbackMount={this.state.callbackMount}/>
        </div>
      </div>
    );
  } 
}

export default injectIntl(Products);

import React from "react";
import "./hero.scss";
import scrollTo from "gatsby-plugin-smoothscroll";
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl";

class Hero extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      callbackMount: this.props.callbackMount
    }
  }

  componentDidMount() {
    // Callback for parent
    this.state.callbackMount('hero');
  }

  render() {
    return (
      <div className="section hero" data-header-color="dark">
        <div className="hero__wrapper centered">
          <div className="hero__wrappertext">
            <h1>
              <FormattedMessage id="hero.title.0" />{" "}
              <span className="hero__wrappertext--highlight">
                <FormattedMessage id="hero.title.1" />
              </span>{" "}
              <FormattedMessage id="hero.title.2" />
            </h1>
            <p className="hero__desc">
              <FormattedMessage id="hero.description" />
            </p>
            <button
              className="hero__button"
              onClick={() => scrollTo(".products")}
            >
              <FormattedMessage id="hero.button" />
            </button>
          </div>
          <div>
            <div className="hero__img">
              <div className="hero__disks"></div>
              <div className="hero__circle_pattern"></div>
              <div className="hero__illu__sphere"></div>
              <div className="hero__illu__square"></div>
              <div className="hero__illu__demi-sphere"></div>
              <div className="hero__illu__cylinder"></div>
            </div>
          </div>
        </div>
      </div>
    );
  } 
}

export default injectIntl(Hero);

import React from "react";
import "./clients.scss";
import logoCarrefour from "../../../images/logo-carrefour.svg";
import logoCoca from "../../../images/logo-coca.svg";
import logoViparis from "../../../images/logo-viparis.svg";
import logoIwc from "../../../images/logo-iwc.svg";
import logoMaif from "../../../images/logo-maif.svg";
import logoVeolia from "../../../images/logo-veolia.svg";
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl";

class Clients extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      callbackMount: this.props.callbackMount
    }
  }

  componentDidMount() {
    // Callback for parent
    this.state.callbackMount('clients');
  }

  render() {
    return (
      <div className="section clients" data-header-color="white">
        <div className="clients__wrapper">
          <div className="clients__section">
            <FormattedMessage id="clients.section" />
          </div>
          <h2 className="clients__title">
            <FormattedMessage id="clients.title" />
          </h2>
          <div className="clients__list">
            <img src={logoCarrefour} className="client" alt="Carrefour" />
            <img src={logoCoca} className="client" alt="Coca-Cola" />
            <img src={logoViparis} className="client" alt="Viparis" />
            <img src={logoIwc} className="client" alt="IWC" />
            <img src={logoMaif} className="client" alt="Maif" />
            <img src={logoVeolia} className="client" alt="Veolia" />
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Clients);